span.MuiTab-wrapper {
	height: 100%;
	/* width: 100%; */
	display: inline-flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: center;
	margin: 0 25px;
	color: #0F406B;
}

.MuiTab-labelIcon {
	min-height: 50px !important;
	padding: 0 !important;
}

.MuiTooltip-tooltip {
	/* background-color: rgba(16,64,107,1) !important; */
	background-color: rgb(189,189,189) !important;
	max-width: none !important;
}

.MuiTabs-indicator {
	background-color:rgba(16,64,107,0.92) !important;
}

.MuiTableFooter-root{
	background-color: rgba(16,64,107,0.92);
}

.MuiTableCell-root {
	font-family: Verlag A, Verlag B !important;
}
.MuiTableCell-root.MuiTablePagination-root {
	padding-right: 60px !important;
	display: inline-flex !important;
	width: 65% !important;
	border: none !important;
}

.MuiTable-root {
	overflow-y: unset;
}

.MuiIconButton-root {
	padding: 8px !important;
}

/* .MuiInputBase-input { */
.MuiInput-root {
	font-family: Verlag A, Verlag B !important;
	color:  rgba(15,64,107,0.92) !important;
	font-size: 1.2rem !important;
}

.MuiInput-underline:before {
	border-bottom: 1px solid rgba(15,64,107,0.5) !important;
}
.MuiInput-underline:after {
	border-bottom: 2px solid rgba(15,64,107,1) !important;
}

.MuiInput-root.Mui-focused,
.Mui-focused {
	color: #0F406B !important;
}

.Mui-checked {
	color: #0F406B !important;
}

.MuiSwitch-track {
	background-color: #0F406B !important;
}

.MuiInputBase-input {
	color: #0F406B !important;
}

.MuiTablePagination-select {
	color: #F5F5F5 !important;
}

.MuiCircularProgress-colorPrimary {
	color: #0F406B !important;
}

.MuiToolbar-regular {
	min-height: 50px !important;
	padding-left: 0px !important;
}

.MuiBottomNavigation-root {
	background-color: aliceblue !important;
}

.MuiStepIcon-root.MuiStepIcon-completed,
.MuiStepIcon-root.MuiStepIcon-active {
	color: #0F406B !important;
}

.MuiTableSortLabel-root.MuiTableSortLabel-active {
	color: rgb(189,189,189) !important;
}

.MuiTypography-caption {
	color: #f5f5f5 !important;
}

div.MuiPaper-root.MuiPaper-elevation2.MuiPaper-rounded {
	overflow: hidden !important;
}

.MuiFormControl-root {
    width: 100% !important;
};